import {
    FETCH_MENU_PENDING,
    FETCH_MENU_ERROR,
    FETCH_MENUS_SUCCESS,
    FETCH_MENUS_LOCATION_SUCCESS, SET_MENU_LOCALE
} from './actions';
import ItemHelper from "../common/ItemHelper";
import i18next from "i18next";
import {getElementTranslation, getElementTranslations} from "../service/languageService";
import Utils from "../../components/utils/Utils";
import AgeRestrictedContext from "../common/AgeRestrictedContext";
import AppConfig from "../common/AppConfig";

const initialState = {
    items: [],
    item_groups: [],
    categories: [],
    modifier_groups: [],
    payment_methods: [],
    menus: [],
    integrations: [],
    promotions: [],
    custom_fields: {},
    hours: [],
    isOpen: null,
    organization: null,
    delivery: null,
    takeaway: null,
    dine_in: null,
    room_service: null,
    pick_date: null,
    estimated_preparation_duration:null,
    estimated_delivery_duration:null,
    in_delivery_area: true,
    delivery_price_money: null,
    min_order_price_money: null,
    settings: [],
    pending: true,
    error: null,
    total: 0,
    types: [],
    itemMultiLevel: false,
}


const getCategoryTranslations = (locale, categories) => {
    return getElementTranslations(locale, categories, ["name", "description"]);
}
const getItemTranslations = (locale, items) => {
    items.forEach(element => {
        element.selected_items.forEach(si => {
            let item = items.filter(x => x.id == si.item_id)[0];
            if(item){
                si.translation = getElementTranslation(locale, item, ["name", "description", "content"]);
            }
        })
    });
    return getElementTranslations(locale, items, ["name", "description", "content"]);
}
const getItemGroupTranslations = (locale, itemGroups) => {
    return getElementTranslations(locale, itemGroups, ["name"]);
}

const getMenuTranslations = (locale, menus) => {
    return getElementTranslations(locale, menus, ["name"]);
}
const getModifierGroupTranslations = (locale, modifierGroups) => {
    return getElementTranslations(locale, modifierGroups, ["name"]);
}

const menuReducer= (state = initialState,action)=>{
    switch(action.type) {
        case FETCH_MENU_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_MENU_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SET_MENU_LOCALE:
            var items = getItemTranslations(action.locale, state.items);
            var categories = getCategoryTranslations(action.locale, state.categories);
            var menus = getCategoryTranslations(action.locale, state.menus);
            var modifierGroups = getModifierGroupTranslations(action.locale, state.modifier_groups)
            var itemGroups = getItemGroupTranslations(action.locale, state.item_groups);
            return {
                ...state,
                items: items,
                categories: categories,
                menus: menus,
                modifier_groups: modifierGroups,
                item_groups: itemGroups,
                locale: action.locale
            }
        case FETCH_MENUS_SUCCESS:
            var data = action.data;
            var locale = i18next.language;
            var showPriceWithMin = data.item_multi_level ? true : false;
            var showDefaultQuantity = AppConfig.isShowOnlyDefaultQuantity();
            data.items.forEach(item => {
                if(item.price === undefined){
                    return ;
                }
                let selectedItems = [];
                let fullPrice = item.price.amount;
                let minPossiblePrice = 0;
                item.selected_items = [];
                item.translation = {
                    name: item.name,
                    description: item.description,
                    content: item.content,
                };
                let isAlcohol = AgeRestrictedContext.isItemAlcohol(item);
                let isEnergyDrink = AgeRestrictedContext.isItemEnergyDrink(item);
                item.modifier_groups.forEach(itemModifierGroup => {
                    let modifierGroup = ItemHelper.getModifierGroup(data.modifier_groups, itemModifierGroup.modifier_group_id);
                    if(modifierGroup === null){
                        return;
                    }
                    let modifierGroupQuantityInfo = ItemHelper.getModifierGroupQuantityInfo(modifierGroup, item);
                    let modifierGroupSelectedItems = [];
                    modifierGroup.options.forEach(modiferGroupOption => {
                        if(modiferGroupOption.type === "ITEM"){
                            let modiferGroupOptionItem = ItemHelper.getItem(data.items, modiferGroupOption.entity_id);
                            if(modiferGroupOptionItem == null){
                                console.log("Error, item not exist: " + modiferGroupOption.entity_id);
                                return;
                            }
                            if(!isAlcohol && AgeRestrictedContext.isItemAlcohol(modiferGroupOptionItem)) isAlcohol = true;
                            if(!isEnergyDrink && AgeRestrictedContext.isItemEnergyDrink(modiferGroupOptionItem)) isEnergyDrink = true;
                            let itemQuantityInfo = ItemHelper.getItemQuantityInfo(modiferGroupOptionItem, item, modifierGroup);

                            if(itemQuantityInfo !== null && (itemQuantityInfo.default_quantity > 0 || itemQuantityInfo.min_permitted > 0)){
                                let selectedItemPrice = ItemHelper.getPriceOvveride(modiferGroupOptionItem, item, modifierGroup);
                                let minQuantityRequired = Utils.zeroIfNull(itemQuantityInfo.default_quantity);
                                if(showPriceWithMin){
                                    minQuantityRequired = minQuantityRequired > Utils.zeroIfNull(itemQuantityInfo.min_permitted) ? minQuantityRequired : Utils.zeroIfNull(itemQuantityInfo.min_permitted);
                                }
                                if(minQuantityRequired > 0){
                                    let totalPrice = 0;
                                    let quantityFree = 0;
                                    if(itemQuantityInfo.charge_above > 0){
                                        let quantityChargeAbove = minQuantityRequired - itemQuantityInfo.charge_above;
                                        if(quantityChargeAbove > 0){
                                            totalPrice = selectedItemPrice * quantityChargeAbove;
                                            quantityFree = itemQuantityInfo.charge_above;
                                        }else{
                                            quantityFree = minQuantityRequired;
                                        }
                                        fullPrice += totalPrice;
                                    }else{
                                        if(selectedItemPrice !== null){
                                            totalPrice = minQuantityRequired * selectedItemPrice;
                                            fullPrice += totalPrice;
                                        }
                                    }

                                    let selectedItem = {
                                        item_id: modiferGroupOptionItem.id,
                                        name: modiferGroupOptionItem.name,
                                        quantity: minQuantityRequired,
                                        total_price: totalPrice,
                                        quantity_free: quantityFree
                                    }
                                    modifierGroupSelectedItems.push(selectedItem);
                                    item.selected_items.push(selectedItem);
                                }
                            }
                        }
                    });
                    if(modifierGroupQuantityInfo !== null && modifierGroupQuantityInfo.charge_above > 0){
                        let chargeAboveQuantity = modifierGroupQuantityInfo.charge_above;
                        modifierGroupSelectedItems.filter(selectedItem => selectedItem.quantity > selectedItem.quantity_free).sort((a, b) => b.price - a.price).forEach(selectedItem => {
                            if(chargeAboveQuantity <= 0){
                                return;
                            }
                            let quantityToFreeCharge = selectedItem.quantity - selectedItem.quantity_free;
                            quantityToFreeCharge = quantityToFreeCharge > chargeAboveQuantity ? chargeAboveQuantity : quantityToFreeCharge;
                            selectedItem.quantity_free += quantityToFreeCharge;
                            let priceForOne =  selectedItem.total_price / selectedItem.quantity;
                            let minusPrice = priceForOne * quantityToFreeCharge;
                            selectedItem.total_price = selectedItem.total_price - minusPrice;
                            chargeAboveQuantity = chargeAboveQuantity - quantityToFreeCharge;
                            fullPrice -= minusPrice;
                        });
                    }
                    if(showPriceWithMin && modifierGroupQuantityInfo !== null && modifierGroupQuantityInfo.min_permitted > 0){
                        let selectedQuantity = modifierGroupSelectedItems.reduce((a, b) => a + b.quantity, 0);
                        let quantityForAddPrice = modifierGroupQuantityInfo.min_permitted - selectedQuantity;
                        if(modifierGroupQuantityInfo.charge_above > 0){
                            quantityForAddPrice = modifierGroupQuantityInfo.charge_above > quantityForAddPrice ? 0 : quantityForAddPrice - modifierGroupQuantityInfo.charge_above;
                        }
                        if(quantityForAddPrice > 0){
                            let addMinPossiblePrice = undefined;
                            modifierGroup.options.forEach(mdo => {
                                let modiferGroupOptionItem = ItemHelper.getItem(data.items, mdo.entity_id);
                                let selectedItemPrice = ItemHelper.getPriceOvveride(modiferGroupOptionItem, item, modifierGroup);
                                if(!selectedItemPrice) selectedItemPrice = 0;

                                if(addMinPossiblePrice === undefined || addMinPossiblePrice > selectedItemPrice){
                                    addMinPossiblePrice = selectedItemPrice;
                                }
                                if(selectedItemPrice<=0) return;
                            })

                            if(addMinPossiblePrice !== undefined && addMinPossiblePrice > 0){
                                minPossiblePrice += addMinPossiblePrice * quantityForAddPrice;
                            }
                            // let firstOption = modifierGroup.options[0];
                            // if(firstOption){
                            //     let modiferGroupOptionItem = ItemHelper.getItem(data.items, firstOption.entity_id);
                            //     let selectedItemPrice = ItemHelper.getPriceOvveride(modiferGroupOptionItem, item, modifierGroup);
                            //     if(selectedItemPrice){
                            //         minPossiblePrice += selectedItemPrice * quantityForAddPrice;
                            //     }
                            // }
                        }
                    }
                });
                minPossiblePrice += fullPrice;
                item.full_price = fullPrice;
                item.min_possible_price = minPossiblePrice;
                item.alcohol = isAlcohol;
                item.energy_drink = isEnergyDrink;
            });

            var items = getItemTranslations(locale, data.items);
            var categories = getCategoryTranslations(locale, data.categories);
            var menus = getMenuTranslations(locale, data.menus);
            var modifierGroups = getModifierGroupTranslations(locale, data.modifier_groups);
            var itemGroups = data.item_groups;
            var isAlcohol = items.some(x => AgeRestrictedContext.isItemAlcohol(x));
            if(isAlcohol){
                AgeRestrictedContext.setAlcohol(true);
            }
            return {
                ...state,
                pending: false,
                items: items,
                item_groups: itemGroups,
                categories: categories,
                modifier_groups: modifierGroups,
                payment_methods: data.payment_methods,
                availabilities: data.availabilities,
                custom_fields: data.custom_fields,
                integrations: data.integrations,
                promotions: data.promotions,
                estimated_delivery_duration: data.estimated_delivery_duration,
                estimated_preparation_duration: data.estimated_preparation_duration,
                in_delivery_area: data.in_delivery_area,
                min_order_price_money: data.min_order_price_money,
                free_delivery_price_money: data.free_delivery_price_money,
                delivery_price_money: data.delivery_price_money,
                organization: data.organization,
                menus: menus,
                hours: data.hours,
                settings: data.settings,
                isOpen: data.isOpen,
                delivery: data.delivery,
                takeaway: data.takeaway,
                dine_in: data.dine_in,
                room_service: data.room_service,
                pick_date: data.pick_date,
                types: data.types,
                itemMultiLevel: data.item_multi_level,
                isAlcohol: isAlcohol
            }
        case FETCH_MENUS_LOCATION_SUCCESS:
            var data = action.data;
            return {
                ...state,
                pending: false,
                estimated_delivery_duration: data.estimated_delivery_duration,
                estimated_preparation_duration: data.estimated_preparation_duration,
                in_delivery_area: data.in_delivery_area,
                delivery_price_money: data.delivery_price_money,
                min_order_price_money: data.min_order_price_money,
                free_delivery_price_money: data.free_delivery_price_money
            }
        default:
            return state;
    }
}

export default menuReducer;
export const getItems = state => state.items;
export const getCategories = state => state.categories;
export const getItemGroups = state => state.item_groups;
export const getModifierGroups = state => state.modifier_groups;
export const getPaymentMethods = state => state.payment_methods;
export const getAvailabilities = state => state.availabilities;
export const getCustomFields = state => state.custom_fields;
export const getMenus = state => state.menus;
export const getMenuPending = state => state.pending;
export const getItemsError = state => state.error;
export const getHours = state => state.hours;
export const isStoreOpen = state => state.isOpen;
export const getOrganization = state => state.organization;
export const getIntegrations = state => state.integrations;
export const getPromotions = state => state.promotions;
export const getOrderTypes = state => state.types;
export const isItemMultiLevel = state => state.itemMultiLevel;
export const isAlcohol = state => state.isAlcohol;
export const getLocationStore = state => {
    return {
        isOpen: state.isOpen,
        delivery: state.delivery,
        takeaway: state.takeaway,
        dine_in: state.dine_in,
        room_service: state.room_service,
        pick_date: state.pick_date,
        estimated_preparation_duration: state.estimated_preparation_duration,
        estimated_delivery_duration: state.estimated_delivery_duration,
        in_delivery_area: state.in_delivery_area,
        delivery_price_money: state.delivery_price_money,
        min_order_price_money: state.min_order_price_money,
        free_delivery_price_money: state.free_delivery_price_money,
        types: state.types
    }
};