import Utils from "../../components/utils/Utils";

class AppConfig {

    static widget = false;
    static darkMode = false;
    static grid = false;
    static cart = true;
    static paid = true;
    static nativeMobile = false;
    static template = false;
    static userRegistration = false;
    static loyaltyPoints = false;
    static loyaltyVouchers = false;
    static loyaltyGroups = false;
    static loyaltyCashback = false;
    static loyaltyQrcode = false;

    static setWidget(widget) {
        this.widget = widget;

        if (window.location.search) {
            let query = window.location.search.substring(1);
            let qp = Utils.getQueryParams(query);
            if (qp.darkMode) {
                this.setDarkMode()
            }
            if (qp.only_items) {
                this.cart = false;
            }
            if (qp.without_paid) {
                this.paid = false;
            }
            if (qp.grid) {
                this.grid = true;
            }
        }
    }

    static setDarkMode() {
        this.darkMode = true;
        var body = document.body;
        body.classList.add("dark-mode");
    }

    static setTemplate(template) {
        this.template = template;
        var body = document.body;
        if (template === undefined) {
            body.classList.add("design-old");
            return;
        }
        switch (template) {
            case "LIGHT":
                body.classList.add("design-old");
                break;
            case "DARK":
                body.classList.add("design-old");
                AppConfig.setDarkMode();
                break;
            case "V2":
            case "V3":
                body.classList.add("design-v2");
                body.classList.add("design-v3");
                break;
        }
    }

    static setSettings(settings) {
        if (settings.module_crm_registration_enabled && settings.module_crm_registration_enabled === "true") {
            this.userRegistration = true;
        }
        if (settings.module_crm_loyalty_points_enabled && settings.module_crm_loyalty_points_enabled === "true") {
            this.loyaltyPoints = true;
        }
        if (settings.module_crm_loyalty_vouchers_enabled && settings.module_crm_loyalty_vouchers_enabled === "true") {
            this.loyaltyVouchers = true;
        }
        if (settings.module_crm_loyalty_groups_enabled && settings.module_crm_loyalty_groups_enabled === "true") {
            this.loyaltyGroups = true;
        }
        if (settings.module_crm_loyalty_cashback_enabled && settings.module_crm_loyalty_cashback_enabled === "true") {
            this.loyaltyCashback = true;
        }
        if (settings.module_crm_loyalty_qrcode && settings.module_crm_loyalty_qrcode === "true") {
            this.loyaltyQrcode = true;
        }
    }

    static isDesignV2() {
        return this.template === "V2";
    }

    static isWidget() {
        return this.widget;
    }

    static setNativeMobile() {
        this.nativeMobile = true;
    }

    static isNativeMobile() {
        return this.nativeMobile;
    }

    static isDarkMode() {
        return this.darkMode;
    }

    static isGrid() {
        return this.grid;
    }

    static isCart() {
        return this.cart;
    }

    static isShowFreeInfo() {
        return false;
    }

    static isDesignV3() {
        return this.template === "V3";
    }

    static isUserRegistration() {
        return this.userRegistration;
    }

    static isUserVouchers() {
        return this.loyaltyVouchers;
    }

    static isUserPoints() {
        return this.loyaltyPoints;
    }

    static isUserGroups() {
        return this.loyaltyGroups;
    }

    static isUserCashback() {
        return this.loyaltyCashback;
    }

    static isUserQrcode() {
        return this.loyaltyQrcode;
    }
    static isShowOnlyDefaultQuantity() {
        return true;
    }

}

export default AppConfig;